import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  components: {},
  data() {
    return {
      formFunctionCode: 'tpm_sale_plan_form',
    };
  },
  methods: {
    setRule(item) {
      const v = item;
      if (v.field === 'orgCode') {
        v.restful = '/mdm/mdmOrgController/select';
        v.restfulParams = { enableStatus: '009' };
        v.optionsKey = { label: 'orgName', value: 'orgCode' };
      } else if (v.field === 'customerCode') {
        v.restful = '/mdm/mdmCustomerMsgController/findCustomerSelectList';
        v.restfulParams = { enableStatus: '009' };
        v.optionsKey = { label: 'customerName', value: 'customerCode' };
      } else if (v.field === 'productCode') {
        v.restful = '/mdm/mdmProductController/productSelectList';
        v.restfulParams = { enableStatus: '009' };
        v.optionsKey = { label: 'productName', value: 'productCode' };
      } else if (v.field === 'productLevelCode') {
        v.restful = '/mdm/mdmProductLevelController/productLevelSelectList';
        v.restfulParams = { enableStatus: '009' };
        v.optionsKey = { label: 'productLevelName', value: 'productLevelCode' };
      }
      return v;
    },
    // 表单渲染完成后的回调
    formComplete() {
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request.get('/tpm/tpmsalesplans/query', {
          id: this.formConfig.row.id,
        }).then((res) => {
          if (res.success) {
            this.setValue(res.result);
          }
        });
      }
      // 设置销售渠道的名称
      const channelCode = this.getRule('channelCode');
      channelCode.on.getLabel = (e) => {
        this.setValue({
          channelName: e || '',
        });
      };
      // 设置产品层级的名称
      const productLevelCode = this.getRule('productLevelCode');
      productLevelCode.on.getLabel = (e) => {
        this.setValue({
          productLevelName: e || '',
        });
      };
    },

    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/tpm/tpmsalesplans/save';
        const params = { ...formData };

        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmsalesplans/update';
          params.id = this.formConfig.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
